<template>
  <div class="header">
    <div class="container header__container">
      <div class="header__container-logoBlock">
        <div class="relative">
          <button @click="resetFilters">
            <base-image
              :src="require('@/assets/img/header/logo.svg')"
              class="header__container-logoBlock-logo"
              contain
              height="45"
              name="logo"
              width="204"
            />
            <span class="header__logo-mobile" />
          </button>
        </div>
        <base-select-input
          :modelValue="currentRegion"
          :options="regionsOptions"
          arrow="expand_more"
          class="header__container-logoBlock-region"
          height="25"
          type="text"
          @update:modelValue="updateRegion($event)"
        />
      </div>

      <div class="header__container-actionsBlock">
        <ActionsBlock />
        <div class="items-in-line gap--9">
          <div class="header__container-actionsBlock-mobileMenu">
            <MenuComponent />
          </div>
          <a
            v-if="useUserStore().user?.access.includes('back')"
            :href="backLink"
            class="header__container-admin-btn center pointer"
            target="_blank"
            >Back</a
          >
          <base-button
            class="no-wrap"
            style-type="coloredBorder"
            text="Объявление +"
            @click="
              checkUserLoginHandler().then(() => {
                router.push({
                  name: 'profile',
                  params: { id: 'myAnnouncements' },
                  hash: '#create',
                });
              })
            "
          />
          <router-link v-if="useUserStore().user" :to="{ name: 'profile' }">
            <base-button text="Профиль" />
          </router-link>
          <base-button v-else text="Войти" @click="useAppStore().openAuthModal" />
        </div>
      </div>
    </div>
    <div class="header__menu">
      <MenuComponent />
    </div>
    <base-divider class="header__mobile-divider" />
  </div>
</template>

<script lang="ts" setup>
import MenuComponent from '@/components/Menu/Menu.vue';
import ActionsBlock from './ActionsBlock/ActionsBlock.vue';
import { useUserStore } from '@/store/modules/user';
import { computed } from 'vue';
import { useAppStore } from '@/store/modules/app';
import { useOfferCardsStore } from '@/store/modules/offerCards';
import { useRouter } from 'vue-router';
import { checkUserLoginHandler } from '@/utils/Common/Common';

const backLink = computed(() => {
  const url =
    process.env.VUE_APP_IS_DEV === 'true'
      ? 'http://localhost:13010'
      : useAppStore().baseUrl;

  return url + `/back`;
});

const currentRegion = computed((): number | undefined => {
  return useAppStore().regions_for_select.defaultForUser;
});

const updateRegion = (region: number) => {
  useAppStore().updateRegion(region);
};
const regionsOptions = computed(() => {
  return useAppStore().regions_for_select.regions;
});
const router = useRouter();
const resetFilters = async () => {
  useOfferCardsStore().returnToInitialState();
  await router.push({ name: 'main' });

  await router.replace({ query: undefined });
};
</script>

<style lang="scss" scoped src="./Header.scss"></style>
