<template>
  <div v-if="isLoading" class="ads-items favorite">
    <base-skeleton-loader
      v-for="i in favoriteAdsCount"
      :key="i"
      :styles="{ height: '132px', width: '100%' }"
      class="mb--5"
      type="mediumText"
    />
  </div>
  <div
    v-else-if="!favoriteAds || favoriteAds.length === 0"
    class="items-in-column align-center"
  >
    <div>Добавьте объявление в избранное</div>
    <base-button :href="{ name: 'main' }" height="40" text="К объявлениям" />
  </div>
  <div v-else class="ads-items favorite">
    <base-card
      v-for="favoriteAd in favoriteAds"
      :key="favoriteAd"
      class="ads-items__section"
      full-width-mobile
    >
      <div class="ads-items__container favorite">
        <router-link :to="{ name: 'offerCard', params: { url: favoriteAd.landUrl } }">
          <base-image
            :src="favoriteAd.img ? favoriteAd.img[0] : undefined"
            border-radius
            cover
            height="98"
            name="img"
            version="v4"
            width="96"
          />
        </router-link>
        <div>
          <div class="mb--10">
            <span class="medium m-font-size">{{ favoriteAd.abbreviation }}</span>
          </div>
          <div class="ads-items__container-info favorite">
            <div>
              <div><span class="dark-text-color">Этаж:</span> {{ favoriteAd.floor }}</div>
              <div>
                <span class="dark-text-color">Дом:</span>
                {{ `${favoriteAd.houseArea || '-'} м²` }}
              </div>
            </div>
            <div>
              <div>
                <span class="dark-text-color">Адрес:</span> {{ favoriteAd.address }}
              </div>
              <div>
                <span class="dark-text-color">Участок: </span
                >{{ `${favoriteAd.landArea || '-'} сот.` }}
              </div>
            </div>
            <div>
              <div>
                <span class="dark-text-color">Цена: </span
                >{{ favoriteAd.price?.toLocaleString() }} ₽
              </div>
              <div><span class="dark-text-color">id: </span>{{ favoriteAd.id }}</div>
            </div>
          </div>
        </div>
        <div class="items-in-column gap--7">
          <base-button
            icon
            style-type="border"
            withoutActive
            @click="deleteFavorite(favoriteAd.landId)"
          >
            <template #icon>
              <base-icon color="#EA1F1F" font-size="20" name="favorite" width="22" />
            </template>
          </base-button>
          <ShareBtn
            :link="`${useAppStore().baseUrl}/offerCard/${favoriteAd.landUrl}`"
            color="#009D3E"
            font-size="20"
            height="28"
            width="28"
          />
          <PhoneButton :phone="favoriteAd.phone" btn-type="border" is-icon phoneModal />
        </div>
      </div>
    </base-card>
  </div>
</template>

<script lang="ts" setup>
import { computed, onMounted, ref, Ref } from 'vue';
import { useUserStore } from '@/store/modules/user';
import { ResultCardType } from '@/types';
import { PhoneButton, ShareBtn } from '@/components';
import { useAppStore } from '@/store/modules/app';

const favoriteAds: Ref<ResultCardType[] | undefined> = ref(undefined);

const isLoading = ref(false);

const deleteFavorite = async (id: number) => {
  await useUserStore().changeIsFavorite(id);
  const favoriteIndex = favoriteAds.value?.findIndex(el => el.landId === id);

  if (favoriteIndex !== undefined && favoriteIndex >= 0) {
    favoriteAds.value?.splice(favoriteIndex, 1);
  }
};
const favoriteAdsCount = computed(() => {
  return useUserStore().user?.favorite_ids.length;
});

onMounted(async () => {
  try {
    isLoading.value = true;
    favoriteAds.value = await useUserStore().getFavoriteHouses();
  } finally {
    isLoading.value = false;
  }
});
</script>

<style lang="scss" scoped src="./AdsItems.scss"></style>
